import Modal from "../Modal";

export default function CompanyModal({
  onClose,
  params,
  prevCompany,
  nextCompany,
  transition,
}) {
  return (
    <Modal onClose={onClose} transition={transition}>
      <div className="modal__buttons">
        <button type="button" className="modal__button" onClick={prevCompany}>
          <img src="/assets/arrow-left.svg" alt="arrow" />
        </button>
        <h2>{params.title}</h2>
        <button type="button" className="modal__button" onClick={nextCompany}>
          <img src="/assets/arrow-right.svg" alt="arrow" />
        </button>
      </div>
      <p>{params.description}</p>
      <a
        href={params.link}
        target="_blank"
        rel="noreferrer"
        className="modal__link"
      >
        Visitar sitio
      </a>
    </Modal>
  );
}
