import { animated, useSpring } from "@react-spring/web";
import { useEffect, useState } from "react";

export default function Splash({ visible: _visible }) {
  const [visible, setVisible] = useState(_visible);

  const [splashProps, splashApi] = useSpring(() => ({
    from: { opacity: 1 },
    to: { opacity: 1 },
    config: {
      mass: 10,
      friction: 50,
      tension: 100,
    },
  }));

  useEffect(() => {
    if (!_visible) {
      splashApi.start({ opacity: 0, onRest: () => setVisible(false) });
    }
  }, [_visible, splashApi]);

  if (!visible) return null;

  return (
    <animated.div className="splash" style={splashProps}>
      <img src={"/assets/kick-logo.svg"} alt="kick" className="brand" />
    </animated.div>
  );
}
