import { useSpring, animated } from "@react-spring/web";

export default function ContentBox({ openModal }) {
  const [boxSpring] = useSpring(() => ({
    from: { opacity: 0, x: -200 },
    to: { opacity: 1, x: 0 },
    config: {
      mass: 10,
      friction: 50,
      tension: 100,
    },
  }));

  const handleContactUs = () => {
    openModal("contact");
  };

  return (
    <animated.div style={boxSpring} className="box">
      <img src={"/assets/kick-logo.svg"} alt="kick" className="brand" />
      <p>
        Somos una empresa tecnológica apasionada por la innovación y la
        inversión en nuevas empresas.
        <br />
        En KICK, creemos en el poder de las ideas y en el potencial ilimitado de
        la tecnología para transformar el mundo que nos rodea.
      </p>
      <button className="contact_button" onClick={handleContactUs}>
        Contactanos
      </button>
    </animated.div>
  );
}
