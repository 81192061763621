import Modal from "../Modal";

export default function ContactModal({ onClose }) {
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    try {
      const response = await fetch("/contact.php", {
        method: "POST",
        body: formData,
      });
      console.log("response", response);
      if (response.ok) {
        onClose();
        alert("Mensaje enviado!");
      } else {
        alert(response.statusText);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <Modal onClose={onClose}>
      <h2>Contactanos</h2>
      <form className="contact_form" onSubmit={handleOnSubmit}>
        <div className="contact_formgroup required">
          <label>Nombres*</label>
          <input type="text" name="name" required />
        </div>
        <div className="contact_formgroup required">
          <label>E-mail*</label>
          <input type="text" name="email" required />
        </div>
        <div className="contact_formgroup">
          <label>Teléfono</label>
          <input type="text" name="phone" />
        </div>
        <div className="contact_formgroup required">
          <label>Mensaje*</label>
          <textarea name="msg" required />
        </div>
        <button type="submit" className="contact_button">
          Enviar
        </button>
      </form>
    </Modal>
  );
}
