import { memo, useEffect, useMemo, useState } from "react";
import { animated, useSpring } from "@react-spring/web";

const assets = [
  {
    file: "0-0-0.png?v=1",
    filex2: "0-0-0@2x.png?v=1",
    filex4: "0-0-0@4x.png?v=1",
    delay: 600,
    anim: "appear-from-behind",
    position: {
      x: 0,
      y: 0,
      z: 0,
    },
  },
  {
    file: "1-369-296.png?v=1",
    filex2: "1-369-296@2x.png?v=1",
    filex4: "1-369-296@4x.png?v=1",
    anim: "appear-from-below",
    delay: 1300,
    position: {
      x: 369,
      y: 296,
      z: 17,
    },
  },
  {
    file: "3-395-367.png?v=1",
    filex2: "3-395-367@2x.png?v=1",
    filex4: "3-395-367@4x.png?v=1",
    anim: "appear-from-below",
    delay: 1300,
    position: {
      x: 395,
      y: 367,
      z: 19,
    },
  },
  {
    file: "2-335-218.png?v=1",
    filex2: "2-335-218@2x.png?v=1",
    filex4: "2-335-218@4x.png?v=1",
    anim: "appear-from-below",
    ref: "kicknerds-building",
    delay: 1800,
    position: {
      x: 335,
      y: 218,
      z: 18,
    },
  },
  {
    file: "4-384-124.png?v=1",
    filex2: "4-384-124@2x.png?v=1",
    filex4: "4-384-124@4x.png?v=1",
    anim: "appear-from-below",
    delay: 1600,
    position: {
      x: 384,
      y: 124,
      z: 16,
    },
  },
  {
    file: "5-561-151.png?v=1",
    filex2: "5-561-151@2x.png?v=1",
    filex4: "5-561-151@4x.png?v=1",
    anim: "appear-from-below",
    ref: "millsonic-building",
    delay: 1500,
    position: {
      x: 561,
      y: 151,
      z: 15,
    },
  },
  {
    file: "6-496-85.png?v=1",
    filex2: "6-496-85@2x.png?v=1",
    filex4: "6-496-85@4x.png?v=1",
    anim: "appear-from-below",
    ref: "entraste-building",
    delay: 1400,
    position: {
      x: 496,
      y: 85,
      z: 14,
    },
  },
  {
    file: "7-328-162.png?v=1",
    filex2: "7-328-162@2x.png?v=1",
    filex4: "7-328-162@4x.png?v=1",
    anim: "appear-from-below",
    delay: 1300,
    position: {
      x: 328,
      y: 162,
      z: 13,
    },
  },
  {
    file: "8-275-147.png?v=1",
    filex2: "8-275-147@2x.png?v=1",
    filex4: "8-275-147@4x.png?v=1",
    anim: "appear-from-below",
    delay: 1200,
    ref: "blocktracker-building",
    position: {
      x: 275,
      y: 147,
      z: 12,
    },
  },
  {
    file: "9-343-102.png?v=1",
    filex2: "9-343-102@2x.png?v=1",
    filex4: "9-343-102@4x.png?v=1",
    anim: "appear-from-below",
    delay: 1100,
    ref: "trokor-building",
    position: {
      x: 343,
      y: 102,
      z: 11,
    },
  },
  {
    file: "10-410-43.png?v=1",
    filex2: "10-410-43@2x.png?v=1",
    filex4: "10-410-43@4x.png?v=1",
    anim: "appear-from-below",
    ref: "biner-building",
    delay: 1000,
    position: {
      x: 410,
      y: 43,
      z: 10,
    },
  },
  {
    file: "11-166-333.png?v=1",
    filex2: "11-166-333@2x.png?v=1",
    filex4: "11-166-333@4x.png?v=1",
    anim: "appear-from-below",
    delay: 2800,
    position: {
      x: 166,
      y: 333,
      z: 8,
    },
  },
  {
    file: "12-377-100.png?v=1",
    filex2: "12-377-100@2x.png?v=1",
    filex4: "12-377-100@4x.png?v=1",
    anim: "fly-from-top",
    ref: "trokor-logo",
    action: "open-modal/trokor",
    delay: 2000,
    position: {
      x: 377 - 59 / 2,
      y: 100 - 29 / 2,
      z: 20,
    },
  },
  {
    file: "13-446-51.png?v=1",
    filex2: "13-446-51@2x.png?v=1",
    filex4: "13-446-51@4x.png?v=1",
    anim: "fly-from-top",
    ref: "biner-logo",
    action: "open-modal/biner",
    delay: 2100,
    position: {
      x: 446 - 42 / 2,
      y: 51 - 51 / 2,
      z: 21,
    },
  },
  {
    file: "14-532-85.png?v=1",
    filex2: "14-532-85@2x.png?v=1",
    filex4: "14-532-85@4x.png?v=1",
    anim: "fly-from-top",
    ref: "entraste-logo",
    action: "open-modal/entraste",
    delay: 2200,
    position: {
      x: 532 - 67 / 2,
      y: 85 - 39 / 2,
      z: 22,
    },
  },
  {
    file: "15-597-157.png?v=1",
    filex2: "15-597-157@2x.png?v=1",
    filex4: "15-597-157@4x.png?v=1",
    anim: "fly-from-top",
    ref: "millsonic-logo",
    action: "open-modal/millsonic",
    delay: 2300,
    position: {
      x: 597 - 59 / 2,
      y: 157 - 41 / 2,
      z: 23,
    },
  },
  {
    file: "16-370-243.png?v=1",
    filex2: "16-370-243@2x.png?v=1",
    filex4: "16-370-243@4x.png?v=1",
    anim: "fly-from-top",
    ref: "kicknerds-logo",
    action: "open-modal/kicknerds",
    delay: 2400,
    position: {
      x: 370 - 40 / 2,
      y: 243 - 34 / 2,
      z: 24,
    },
  },
  {
    file: "17-308-166.png?v=1",
    filex2: "17-308-166@2x.png?v=1",
    filex4: "17-308-166@4x.png?v=1",
    anim: "fly-from-top",
    ref: "blocktracker-logo",
    action: "open-modal/blocktracker",
    delay: 2500,
    position: {
      x: 310 - 66 / 2,
      y: 166 - 21 / 2,
      z: 25,
    },
  },
  {
    file: "18-229-364.png?v=1",
    filex2: "18-229-364@2x.png?v=1",
    filex4: "18-229-364@4x.png?v=1",
    anim: "fly-left-down",
    delay: 6200,
    position: {
      x: 269 - 62 / 2,
      y: 344 - 62 / 2,
      z: 9,
    },
  },
  {
    file: "19-519-296.png?v=1",
    filex2: "19-519-296@2x.png?v=1",
    filex4: "19-519-296@4x.png?v=1",
    anim: "fly-right-up",
    delay: 2700,
    position: {
      x: 519 - 84 / 2,
      y: 296 - 59 / 2,
      z: 27,
    },
  },
];

const MAX_CANVAS_WIDTH = 660;
const AXIS_REFERENCE_X = 455;
const MIN_OFFSET_WIDTH = (MAX_CANVAS_WIDTH - AXIS_REFERENCE_X) * 2;

function Illustration({ openModal }) {
  const [zoom, setZoom] = useState(null);
  const [wrapperOffset, setWrapperOffset] = useState(0);

  const scale = useMemo(() => {
    if (zoom <= 1) return 1;
    if (zoom <= 2) return 2;
    return 4;
  }, [zoom]);

  const adjustedScale = zoom / scale;

  useEffect(() => {
    // add window resize listener
    const resizeCallback = () => {
      const newZoom = Math.min(
        4,
        Math.max(1, Math.floor(window.innerWidth / MAX_CANVAS_WIDTH))
      );

      console.log(
        "resize",
        window.innerWidth,
        newZoom,
        MAX_CANVAS_WIDTH * newZoom
      );
      if (window.innerWidth < MAX_CANVAS_WIDTH * newZoom) {
        let newOffset =
          MAX_CANVAS_WIDTH * newZoom -
          window.innerWidth -
          Math.max(0, ((MIN_OFFSET_WIDTH - window.innerWidth) * newZoom) / 2);
        console.log(
          "newOffset",
          newOffset + "px",
          MAX_CANVAS_WIDTH * newZoom - window.innerWidth + "px",
          window.innerWidth + "px",
          " <? ",
          MIN_OFFSET_WIDTH + "px",
          " --- adjustment: ",
          Math.max(0, ((MIN_OFFSET_WIDTH - window.innerWidth) * newZoom) / 2) +
            "px"
        );
        setWrapperOffset(newOffset);
      } else {
        setWrapperOffset(0);
      }

      setZoom((zoom) => {
        return newZoom;
      });
    };
    window.addEventListener("resize", resizeCallback);
    resizeCallback();
    return () => {
      window.removeEventListener("resize", resizeCallback);
    };
  }, []);

  const handleAssetAction = (action) => {
    console.log("handle ", action);
    if (action.indexOf("open-modal/") === 0) {
      openModal(action.replace("open-modal/", ""));
    }
  };
  console.log("Illustration rerender");
  if (!zoom) return null;
  return (
    <div
      style={{
        width: MAX_CANVAS_WIDTH * zoom,
        height: 400 * zoom,
      }}
      className="illustration-container"
    >
      {/* <div className="zoom-controls">
        <button
          onClick={() => setZoom((prev) => Math.max(1, prev - 1))}
          disabled={zoom === 1}
        >
          -
        </button>
        <span style={{ color: "white" }}>{zoom}x</span>
        <button
          onClick={() => setZoom((prev) => Math.min(4, prev + 1))}
          disabled={zoom === 4}
        >
          +
        </button>
      </div> */}
      <div className="illustration-wrapper" style={{ right: wrapperOffset }}>
        {assets.map((asset, index) => (
          <MemoAnimWrapper
            key={index}
            animation={asset?.anim}
            scale={zoom}
            style={{
              position: "absolute",
              top: Array.isArray(asset)
                ? asset[0].position.y * zoom
                : asset.position.y * zoom,
              left: Array.isArray(asset)
                ? asset[0].position.x * zoom
                : asset.position.x * zoom,
              zIndex: asset.position.z,
            }}
            delay={asset.delay}
            className={asset?.ref ? asset.ref : "asset"}
          >
            {asset.action ? (
              <button
                className="asset-button"
                onClick={() => handleAssetAction(asset.action)}
              >
                <img
                  style={{
                    transform: `translate(-50%,-50%) scale(${adjustedScale}) translate(50%,50%)`,
                  }}
                  src={`assets/${
                    scale === 4
                      ? asset.filex4
                      : scale === 2
                      ? asset.filex2
                      : asset.file
                  }`}
                  alt="asset"
                />
              </button>
            ) : (
              <img
                style={{
                  transform: `translate(-50%,-50%) scale(${adjustedScale}) translate(50%,50%)`,
                }}
                src={`assets/${
                  scale === 4
                    ? asset.filex4
                    : scale === 2
                    ? asset.filex2
                    : asset.file
                }`}
                alt="asset"
              />
            )}
          </MemoAnimWrapper>
        ))}
      </div>
    </div>
  );
}

export default memo(Illustration);

function AnimWrapper({
  children,
  animation,
  delay = 0,
  style,
  scale = 1,
  className = "",
}) {
  const springProps = useMemo(
    () => ({
      "appear-from-below": {
        from: { y: 700 * scale },
        to: { y: 0 },
        config: {
          mass: 10,
          friction: 120,
          tension: 100,
        },
      },
      "fly-right-up": {
        from: { x: -500 * scale, y: 300 * scale },
        to: { x: 500 * scale, y: -300 * scale },
        config: {
          mass: 100,
          friction: 100,
          tension: 50,
        },
        loop: true,
      },
      "fly-left-down": {
        from: { x: 200 * scale, y: 180 * scale },
        to: [
          { x: 200 * scale, y: -120 * scale },
          { x: 200 * scale, y: -120 * scale },
          { x: -300 * scale, y: 180 * scale },
        ],
        config: {
          mass: 30,
          friction: 150,
          tension: 100,
        },
        loop: true,
      },
      "fly-from-top": {
        from: { y: -300 * scale, opacity: 0 },
        to: { y: 0 * scale, opacity: 1 },
        config: {
          mass: 20,
          friction: 20,
          tension: 50,
        },
      },
      "appear-from-behind": {
        from: { scale: 0.8, opacity: 0, y: 100 * scale },
        to: { scale: 1, opacity: 1, y: 0 * scale },
        config: {
          mass: 10,
          friction: 200,
          tension: 100,
        },
      },
    }),
    [scale]
  );

  const [props, api] = useSpring(() => ({
    from: springProps[animation]?.from,
    delay: delay,
  }));

  useEffect(() => {
    setTimeout(() => {
      api.start(springProps[animation]);
    }, delay);
  }, [animation, delay, props, api, springProps]);

  return (
    <animated.div style={{ ...style, ...props }} className={className}>
      {children}
    </animated.div>
  );
}

const MemoAnimWrapper = memo(AnimWrapper);
