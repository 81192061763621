import { useSpring, animated } from "@react-spring/web";

export default function Modal({ children, onClose, transition = true }) {
  const [backgroundProps, backgroundApi] = useSpring(() => ({
    from: { opacity: transition ? 0 : 1 },
    to: { opacity: 1 },
    config: {
      mass: 10,
      friction: 50,
      tension: 100,
    },
  }));

  const [containerProps, containerApi] = useSpring(() => ({
    from: transition ? { y: -100, scale: 0.5 } : { y: 0, scale: 1 },
    to: { y: 0, scale: 1, opacity: 1 },
    config: {
      mass: 1,
      friction: 30,
      tension: 200,
    },
  }));

  const handleOnClose = () => {
    backgroundApi.start({ opacity: 0 });
    containerApi.start({ y: -100, scale: 0.5, opacity: 0, onRest: onClose });
  };

  return (
    <>
      <animated.div
        className="modal__overlay"
        style={backgroundProps}
        onClick={handleOnClose}
      />
      <div className="modal">
        <animated.div className="modal__container" style={containerProps}>
          <div className="modal__content">
            <div className="modal__header">
              <button className="modal__close" onClick={handleOnClose}>
                <img src="/assets/close.svg" alt="close" />
              </button>
            </div>
            <div className="modal__body">{children}</div>
          </div>
        </animated.div>
      </div>
    </>
  );
}
